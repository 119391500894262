import React, { useState } from 'react'
// import logo from '../img/Logo_RO_Orizontal.webp'
import arbore from '../img/Aborele/Arborele-unei-familii.webp'
import arborepics from '../img/Aborele/arbore-img.png'
import key from '../img/Aborele/key.svg'

import { useTranslation } from 'react-i18next'

const Arborele = () => {
  const { t } = useTranslation()
  const [readMore1, setReadMore1] = useState(false)
  const [readMore2, setReadMore2] = useState(false)
  const [readMore3, setReadMore3] = useState(false)
  const [readMore4, setReadMore4] = useState(false)

  const extraContent1 = (
    <div>
      <p className='p-chei'>{t('tendinte')}</p>
      <ul>
        <li>{t('cheia-materiala3')}</li>
        <li> {t('cheia-materiala4')}</li>
      </ul>
      <p className='p-chei'>{t('paradigma')}</p>
      <ul>
        <li>{t('cheia-materiala5')}</li>
      </ul>
    </div>
  )
  const extraContent2 = (
    <div>
      <p className='p-chei'>{t('tendinte')}</p>
      <ul>
        <li>{t('cheia-sexuala3')}</li>
        <li> {t('cheia-sexuala4')}</li>
        <li> {t('cheia-sexuala5')}</li>
      </ul>
      <p className='p-chei'>{t('paradigma')}</p>
      <ul>
        <li>{t('cheia-sexuala6')}</li>
      </ul>
    </div>
  )
  const extraContent3 = (
    <div>
      <p className='p-chei'>{t('tendinte')}</p>
      <ul>
        <li>{t('cheia-emotionala3')}</li>
      </ul>
      <p className='p-chei'>{t('paradigma')}</p>
      <ul>
        <li>{t('cheia-emotionala4')}</li>
      </ul>{' '}
    </div>
  )

  const extraContent4 = (
    <div>
      <p className='p-chei'>{t('paradigma')}</p>
      <ul>
        <li>{t('cheia-intelectuala3')}</li>
      </ul>
    </div>
  )

  const linkName1 = readMore1 ? 'CITEȘTE MAI PUȚIN' : 'CITEȘTE MAI MULT'
  const linkName2 = readMore2 ? 'CITEȘTE MAI PUȚIN' : 'CITEȘTE MAI MULT'
  const linkName3 = readMore3 ? 'CITEȘTE MAI PUȚIN' : 'CITEȘTE MAI MULT'
  const linkName4 = readMore4 ? 'CITEȘTE MAI PUȚIN' : 'CITEȘTE MAI MULT'

  return (
    <div>
      <div className='jumbotron jumbotron-fluid mb-0 arborele bgc-dark'>
        <div className='container'>
          <div className='row'></div>
        </div>
      </div>
      <div className='container-fluid bgc-dark breakline'>
        <div className='row'>
          <div className='col-lg-12 text-center mt-5 poppins'>
            {t('arborele-de')}
          </div>
          <div className='col-lg-12 text-center mt-2 mb-4 dcc'>
            {t('familie')}
          </div>
          <div className='row mt-5'>
            <div className='col-lg-12 text-center poppins-white'>
              {t('arbore1')}
            </div>
            <div className='col-lg-12 text-center poppins-white'>
              {t('arbore2')}
            </div>
          </div>
          <div class='container mt-5'>
            <div class='row h-100'>
              <div class='mx-auto'>
                <div class='jumbotron jumbotron-arbore text-center'>
                  <div className='poppins-white-sm'>{t('arbore3')}</div>
                  <div className='poppins-white-sm mt-4'>{t('arbore4')}</div>
                  <div className='poppins-white-sm mt-4'>{t('arbore5')}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-5'>
          <div className='col-lg-12 text-center mt-5 mb-5'>
            <img src={arbore} className='img-fluid' alt='Family tree'></img>
          </div>
        </div>
        {/* <div className='row'>
          <div className='col-lg-12 text-center mt-5 mb-5'>
            <button type='button' className='btn btn-outline-danger'>
              {t('abuzbutton')}
            </button>{' '}
          </div>
        </div> */}
      </div>
      <div className='jumbotron jumbotron-fluid mb-0 arborele-scopul bgc-dark breakline'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-7 poppins-white mt-5'>
              {t('arbore6')}
              <br></br>
              <div className='mt-5'>{t('arbore7')}</div>
            </div>
            <div className='col-lg-5 arborepic'>
              <img src={arborepics} alt='' />
            </div>
          </div>
        </div>
      </div>
      <div className='jumbotron jumbotron-fluid mb-0 mt-5 breakline jumbo-arbore-white text-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 poppins-bold-black mt-5'>
              <h1 style={{ letterSpacing: '2px' }}>
                <strong>{t('arbore8')}</strong>
              </h1>
              <h6 className='mt-5'>{t('arbore9')}</h6>
              <h3 className='mt-5'>
                <strong>{t('arbore10')}</strong>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid bgc-dark container-chei mt-5 d-flex align-items-start'>
        <div className='row'>
          <div className='col-md-3 border-right'>
            <h3 className='mt-5 mb-2'>
              <span>
                <img src={key} alt='' />
              </span>
              {t('cheia-materiala')}
            </h3>
            <p className='mt-5'>
              <span>{t('focus')}</span>
              {t('cheia-materiala1')}
            </p>
            <p>
              <span>{t('influente')}</span>
              {t('cheia-materiala2')}
            </p>
            <a
              className='read-more-link'
              onClick={() => {
                setReadMore1(!readMore1)
              }}
            >
              <span>
                <br></br>
                {linkName1}
              </span>
            </a>
            {readMore1 && extraContent1}
          </div>{' '}
          <div className='col-md-3 border-right'>
            <h3 className='mt-5'>
              <span>
                <img src={key} alt='' />
              </span>
              {t('cheia-sexuala')}
            </h3>
            <p>
              <span>{t('focus')}</span>
              {t('cheia-sexuala1')}
            </p>
            <p>
              <span>{t('influente')}</span>
              {t('cheia-sexuala2')}
            </p>
            <a
              className='read-more-link'
              onClick={() => {
                setReadMore2(!readMore2)
              }}
            >
              <span>
                <br></br>
                {linkName2}
              </span>
            </a>
            {readMore2 && extraContent2}
          </div>{' '}
          <div className='col-md-3 border-right'>
            <h3 className='mt-5'>
              <span>
                <img src={key} alt='' />
              </span>
              {t('cheia-emotionala')}
            </h3>
            <p>
              <span>{t('focus')}</span>
              {t('cheia-emotionala1')}
            </p>
            <p>
              <span>{t('influente')}</span>
              {t('cheia-emotionala2')}
            </p>
            <a
              className='read-more-link'
              onClick={() => {
                setReadMore3(!readMore3)
              }}
            >
              <span>
                <br></br>
                {linkName3}
              </span>
            </a>
            {readMore3 && extraContent3}
          </div>{' '}
          <div className='col-md-3 mt-4'>
            <h3 className='mt-5'>
              <span>
                <img src={key} alt='' />
              </span>
              {t('cheia-intelectuala')}
            </h3>
            <p>
              <span>{t('focus')}</span>
              {t('cheia-intelectuala1')}
            </p>
            <p>
              <span>{t('influente')}</span>
              {t('cheia-intelectuala2')}
            </p>
            <a
              className='read-more-link'
              onClick={() => {
                setReadMore4(!readMore4)
              }}
            >
              <span>
                <br></br>
                {linkName4}
              </span>
            </a>
            {readMore4 && extraContent4}
          </div>{' '}
        </div>
      </div>
    </div>
  )
}

export default Arborele
