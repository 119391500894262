import React from 'react'
// import logo from '../img/Logo_RO_Orizontal.webp'
import arbore from '../img/Aborele/Arborele-unei-familii.webp'
import arborepics from '../img/Aborele/arbore-img.png'
import headervoluntariat from '../img/Voluntariat/Anca-cutie2.png'
import ancavoluntariat from '../img/Voluntariat/Anca-cutie.png'
import imgfriends from '../img/Voluntariat/img-friends.png'
import { useTranslation } from 'react-i18next'

const Voluntariat = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='jumbotron jumbotron-fluid mb-0 voluntariat bgc-dark'>
        <div className='container-fluid poppins-white'>
          <div className='row'>
            <div className='col-md-6'></div>
            <div className='col-lg-6 text-center voluntariat-header-text'>
              <h1 className='voluntariat-header'>
                <strong>{t('voluntariat1')}</strong>
              </h1>
              <h3 className='voluntariat-header2'>{t('voluntariat2')}</h3>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid container-white'></div>
      <div className='jumbotron jumbotron-fluid mb-0 profilul-voluntarului'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-1'></div>
            <div className='col-lg-6 mt-5'>
              <h4
                style={{ color: 'red', fontSize: '27px' }}
                className='d-flex justify-content-left'
              >
                {t('voluntariat3')}
              </h4>
              <h5 className='poppins-bold-black'>{t('voluntariat4')}</h5>
              <h5 className='poppins-bold-black mt-3'>{t('voluntariat5')}</h5>
            </div>
            <div className='col-lg-5'>
              <img src={imgfriends} className='img-friends img-fluid'></img>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid info-contact text-center'>
        <div className='row'>
          <div className='col-lg-2'></div>
          <div className='col-lg-8 mt-4 poppins-white'>
            <h5>{t('voluntariat-contact')}</h5>
          </div>
          <div className='col-lg-2'></div>
        </div>
        <a href='/contact'>
          {' '}
          <button type='button' className='btn btn-outline-danger-full mt-3'>
            {t('abuzbutton')}
          </button>
        </a>
      </div>
      <div className='container mt-5 container-cards-voluntariat mb-5'>
        <div class='row row-cols-1 row-cols-md-3 g-4'>
          <div class='col'>
            <div class='card'>
              <div class='card-body'>
                <h5 class='card-title'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-2'>
                        {' '}
                        <span>#</span>
                      </div>
                      <div className='col-md-10'>
                        {t('voluntariat-titlu-1')}
                      </div>
                    </div>
                  </div>
                </h5>
                <hr></hr>
                <div class='card-text'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ul>
                          <li>
                            <span>{t('voluntariat-card1')}</span>
                            {t('voluntariat-card11')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card2')}</span>
                            {t('voluntariat-card12')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card3')}</span>
                            {t('voluntariat-card13')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col'>
            <div class='card'>
              <div class='card-body'>
                <h5 class='card-title'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-2'>
                        {' '}
                        <span>#</span>
                      </div>
                      <div className='col-md-10'>
                        {t('voluntariat-titlu-2')}
                      </div>
                    </div>
                  </div>
                </h5>
                <hr></hr>
                <div class='card-text'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ul>
                          <li>
                            <span>{t('voluntariat-card1')}</span>
                            {t('voluntariat-card21')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card2')}</span>
                            {t('voluntariat-card22')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card3')}</span>
                            {t('voluntariat-card23')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col'>
            <div class='card'>
              <div class='card-body'>
                <h5 class='card-title'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-2'>
                        {' '}
                        <span>#</span>
                      </div>
                      <div className='col-md-10'>
                        {t('voluntariat-titlu-3')}
                      </div>
                    </div>
                  </div>
                </h5>
                <hr></hr>
                <div class='card-text'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ul>
                          <li>
                            <span>{t('voluntariat-card1')}</span>
                            {t('voluntariat-card31')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card2')}</span>
                            {t('voluntariat-card32')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card3')}</span>
                            {t('voluntariat-card33')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col'>
            <div class='card'>
              <div class='card-body'>
                <h5 class='card-title'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-2'>
                        {' '}
                        <span>#</span>
                      </div>
                      <div className='col-md-10'>
                        {t('voluntariat-titlu-4')}
                      </div>
                    </div>
                  </div>
                </h5>
                <hr></hr>
                <div class='card-text'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ul>
                          <li>
                            <span>{t('voluntariat-card1')}</span>
                            {t('voluntariat-card41')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card2')}</span>
                            {t('voluntariat-card42')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card3')}</span>
                            {t('voluntariat-card43')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col'>
            <div class='card'>
              <div class='card-body'>
                <h5 class='card-title'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-2'>
                        {' '}
                        <span>#</span>
                      </div>
                      <div className='col-md-10'>
                        {t('voluntariat-titlu-5')}
                      </div>
                    </div>
                  </div>
                </h5>
                <hr></hr>
                <div class='card-text'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ul>
                          <li>
                            <span>{t('voluntariat-card1')}</span>
                            {t('voluntariat-card51')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card2')}</span>
                            {t('voluntariat-card52')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card3')}</span>
                            {t('voluntariat-card53')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col'>
            <div class='card'>
              <div class='card-body'>
                <h5 class='card-title'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-2'>
                        {' '}
                        <span>#</span>
                      </div>
                      <div className='col-md-10'>
                        {t('voluntariat-titlu-6')}
                      </div>
                    </div>
                  </div>
                </h5>
                <hr></hr>
                <div class='card-text'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ul>
                          <li>
                            <span>{t('voluntariat-card1')}</span>
                            {t('voluntariat-card61')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card2')}</span>
                            {t('voluntariat-card62')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card3')}</span>
                            {t('voluntariat-card63')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col'>
            <div class='card'>
              <div class='card-body'>
                <h5 class='card-title'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-2'>
                        {' '}
                        <span>#</span>
                      </div>
                      <div className='col-md-10 mt-3'>
                        {t('voluntariat-titlu-7')}
                      </div>
                    </div>
                  </div>
                </h5>
                <hr></hr>
                <div class='card-text'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ul>
                          <li>
                            <span>{t('voluntariat-card1')}</span>
                            {t('voluntariat-card71')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card2')}</span>
                            {t('voluntariat-card72')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card3')}</span>
                            {t('voluntariat-card73')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col'>
            <div class='card'>
              <div class='card-body'>
                <div className='container container-more text-center'>
                  <div className='row'>
                    <div className='col-md-12 mt-3'>
                      <h2>
                        <b>{t('voluntariat-titlu-8')}</b>{' '}
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='col'>
            <div class='card'>
              <div class='card-body'>
                <h5 class='card-title'>
                  <div className='container-fluid'>
                    <div className='row'>
                      <div className='col-md-2'>
                        {' '}
                        <span>#</span>
                      </div>
                      <div className='col-md-10'>
                        {t('voluntariat-titlu-9')}
                      </div>
                    </div>
                  </div>
                </h5>
                <hr></hr>
                <div class='card-text'>
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-12'>
                        <ul>
                          <li>
                            <span>{t('voluntariat-card1')}</span>
                            {t('voluntariat-card81')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card2')}</span>
                            {t('voluntariat-card82')}
                          </li>
                          <li>
                            <span>{t('voluntariat-card3')}</span>
                            {t('voluntariat-card83')}
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid info-contact2 info-contact-img breakline'>
        <div className='row pt-5'>
          <div className='col-md-2'></div>
          <div className='col-md-6 mt-5'>
            <h2 className=''>
              <b>{t('v-multumim')}</b>
            </h2>
            <h5 className='mt-4'>{t('voluntariat-m')}</h5>
            <a href='/contact'>
              <button type='button' className='btn btn-outline-danger mt-4'>
                {t('abuzbutton')}
              </button>
            </a>
          </div>
          <div className='col-md-4'>
            {/* <img src={ancavoluntariat} alt='' /> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Voluntariat
