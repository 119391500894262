import React, { useState, useEffect } from 'react'
import i18n from 'i18next'
import preventie from '../img/Preventie/hands.png'
import hug from '../img/Preventie/hug.png'
import cards1 from '../img/Preventie/cards1.png'
import cards2 from '../img/Preventie/cards2.png'
import cards3 from '../img/Preventie/cards3.png'
import donation from '../img/Preventie/preventie.png'

import { useTranslation } from 'react-i18next'

const Preventie = () => {
  const { t } = useTranslation()

  return (
    <div>
      <div className='jumbotron jumbotron-fluid mb-0 preventie bgc-dark breakline'>
        <div className='container-fluid poppins-white'>
          <div className='row'>
            <div className='col-md-6'>
              <img className='img-fluid' src={preventie} alt='' />
            </div>
            <div className='col-lg-6 mt-5 text-center preventie-header-text'>
              <h1 className='voluntariat-header'>
                <strong>{t('preventie-parteneriat')}</strong>
              </h1>
              <h3 className='voluntariat-header2'>{t('preventie-scoala')}</h3>
              <h6 className='mt-3'>{t('preventie-header')}</h6>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid container-white'></div>
      <div className='jumbotron jumbotron-fluid mb-0 profilul-voluntarului'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-1'></div>
            <div className='col-lg-6 mt-5'>
              <h4
                style={{ color: 'red', fontSize: '27px' }}
                className='d-flex justify-content-left'
              >
                <b>{t('preventie-1')}</b>
              </h4>
              <h5 className='poppins-bold-black mt-4'>{t('preventie-2')}</h5>
            </div>
            <div className='col-lg-5'>
              <img src={hug} className='img-friends img-fluid'></img>
            </div>
          </div>
        </div>
      </div>
      <div className='container container-campanii mb-3'>
        <div className='row mt-5'>
          <div className='col-lg-12 text-center'>
            <h2>
              <b>{t('campaniile')}</b>
            </h2>
          </div>
        </div>
        <hr></hr>
        <div className='card-group mt-5'>
          <div className='card card-preventie'>
            <img className='card-img-top' src={cards1} alt='...' />
            <div className='card-body'>
              <h5 className='card-title'>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-md-2'>
                      <span>#</span>
                    </div>
                    <div className='col-md-8 mt-3'> {t('campaniile-t-1')}</div>
                  </div>
                </div>
              </h5>
              <p className='card-text'>{t('campanii-card1')}</p>
            </div>
            <div class='card-footer'>
              <a href='/contact'>
                <button
                  type='button'
                  className='btn btn-outline-danger btn-implicate'
                >
                  {t('btn-implica-te')}
                </button>
              </a>
            </div>
          </div>
          <div className='card card-preventie'>
            <img className='card-img-top' src={cards2} alt='' />
            <div className='card-body'>
              <h5 className='card-title'>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-md-2'>
                      <span>#</span>
                    </div>
                    <div className='col-md-8'> {t('campaniile-t-2')}</div>
                  </div>
                </div>
              </h5>
              <p className='card-text'>{t('campanii-card2')}</p>
            </div>
            <div class='card-footer'>
              <a href='/contact'>
                <button
                  type='button'
                  className='btn btn-outline-danger btn-implicate'
                >
                  {t('btn-implica-te')}
                </button>
              </a>
            </div>
          </div>
          <div className='card card-preventie'>
            <img className='card-img-top' src={cards3} alt='...' />
            <div className='card-body'>
              <h5 className='card-title'>
                <div className='container-fluid'>
                  <div className='row'>
                    <div className='col-md-2'>
                      <span>#</span>
                    </div>
                    <div className='col-md-8'> {t('campaniile-t-3')}</div>
                  </div>
                </div>
              </h5>
              <p className='card-text'>{t('campanii-card3')}</p>
            </div>
            <div class='card-footer'>
              <a href='/contact'>
                <button
                  type='button'
                  className='btn btn-outline-danger btn-implicate'
                >
                  {t('btn-implica-te')}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className='container-fluid container-programe mt-5'>
        <div className='row'>
          <div className='col-lg-5'></div>
          <div className='col-lg-7'>
            <h2 className='text-center mb-5 mt-3'>
              <b>{t('campanii-programe')}</b>
            </h2>
            <div class='card card-programe mb-3'>
              <div class='card-body'>
                <div class='card-text'>
                  <h5>{t('campanii-prog1')}</h5>
                  <div className='mt-3'>
                    <span># </span>
                    {t('campanii-prog2')}
                  </div>
                  <hr></hr>
                  <h5 className='mt-3'>{t('campanii-prog3')}</h5>
                  <div className='mt-4'>
                    <span># </span>
                    {t('campanii-prog4')}
                  </div>
                  <div className='mt-4'>
                    <span># </span>
                    {t('campanii-prog5')}
                  </div>
                  <div className='mt-4'>
                    <span># </span>
                    {t('campanii-prog6')}
                  </div>
                  <div className='mt-4'>
                    <span># </span>
                    {t('campanii-prog7')}
                  </div>
                  <div className='mt-4'>
                    <span># </span>
                    {t('campanii-prog8')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Preventie
