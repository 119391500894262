import { React, Suspense, useState } from 'react'
import logoRO from '../img/Logo_RO_Orizontal.webp'
import logoEN from '../img/Logo_EN_Orizontal.webp'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Nav = () => {
  const { t, i18n } = useTranslation()

  // const [language, setLanguage] = useState('')
  // const handleClick = (e) => {
  //   setLanguage(e.target.value)
  //   console.log(e.target.value)
  //   i18n.changeLanguage(e.target.value)
  // }

  function handleChange(evt) {
    i18n.changeLanguage(evt.target.value)
    if (evt.target.value == 'en') {
      document.getElementById('logo').src = logoEN
    } else {
      document.getElementById('logo').src = logoRO
    }
  }

  return (
    <Suspense fallback={'Loading...'}>
      <nav className='navbar navbar-expand-lg nav-vertical navbar-dark bgc-dark sticky-top'>
        <a className='navbar-brand pe-3' href='/'>
          <img
            src={logoRO}
            className='d-inline-block align-top brand'
            alt='CUTIA ROSIE'
            id='logo'
          ></img>
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav  mr-auto navbar-center pl-5'>
            {/* <li className="nav-item active pe-3">
              <NavLink to='/services' className="nav-link" activeClassName="main-nav-active">Services</NavLink>
            </li> */}
            <li className='nav-item pe-3'>
              <NavLink to='/comingsoon' className='nav-link'>
                {t('desprenoi')}
              </NavLink>
            </li>
            <li className='nav-item pe-3'>
              <NavLink to='/abuz' className='nav-link'>
                {t('abuzdomestic')}
              </NavLink>
            </li>
            <li className='nav-item pe-3'>
              <NavLink to='/preventie' className='nav-link single'>
                {t('programepreventie')}
              </NavLink>
            </li>
            <li className='nav-item pe-3'>
              <NavLink to='/voluntariat' className='nav-link single'>
                {t('programevoluntariat')}
              </NavLink>
            </li>
            <li className='nav-item pe-3'>
              <NavLink to='/arborele-de-familie' className='nav-link single'>
                {t('arboreledefamilie')}
              </NavLink>
            </li>
            <li className='nav-item pe-4'>
              <NavLink to='/contact' className='nav-link'>
                {t('contact')}
              </NavLink>
            </li>
            {/* <li className='mt-2 list-lang-desktop'>
              <nav className='slidemenu'>
                <input
                  type='radio'
                  name='slideItem'
                  id='slide-item-1'
                  className='slide-toggle'
                  //checked
                  value='ro'
                  // onChange={handleClick}
                  name='language'
                />
                <label for='slide-item-1' value='ro'>
                  <span>RO</span>
                </label>

                <input
                  type='radio'
                  name='slideItem'
                  id='slide-item-2'
                  className='slide-toggle'
                  value='en'
                  // onChange={handleClick}
                  name='language'
                />
                <label for='slide-item-2' value='en'>
                  <span>EN</span>
                </label>

                <div className='clear'></div>

                <div className='slider'>
                  <div className='bar'></div>
                </div>
              </nav>
            </li>

            <li className='list-lang-mobile'>
              <select id='lang' name='language' onChange={handleChange}>
                <option value='ro'>RO</option>
                <option value='en'>EN</option>
              </select>
            </li> */}
          </ul>
        </div>
      </nav>
    </Suspense>
  )
}

export default Nav
