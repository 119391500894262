import React, { useState, useEffect } from 'react'
import logo from '../img/Logo_RO_Orizontal.webp'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { t } = useTranslation()

  const [date, setDate] = useState()

  const getYear = () => setDate(new Date().getFullYear())

  useEffect(() => {
    getYear()
  }, [])
  return (
    <div>
      <div className='jumbotron jumbotron-fluid mb-0 mt-0 bgc-dark footer-jumbotron'>
        <div className='container'>
          <div className='row text-center pt-5'>
            <div className='col-lg-12'>
              <img src={logo} alt='' className='img-fluid' />
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col-lg-12'>
              <h1 className='text-center font-weight-bold bold-gold'>
                <a href='https://www.facebook.com/Cutia-Rosie-103602362228922/'>
                  <i className='fab fa-facebook'></i>
                </a>
              </h1>
              <h5 className='text-center mt-3 mail-footer'>
                <a className='text-white' href='mailto:contact@cutiarosie.ro'>
                  contact@cutiarosie.ro
                </a>
              </h5>
            </div>
          </div>
          <div className='row mt-2 footer-lower'>
            <div className='col-lg-12'>
              <p>
                © {date} {t('footer-drepturi')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
