import React from 'react'
import logo from '../img/Logo_RO_Orizontal.webp'
import logomobileRO from '../img/Logo_RO_Vertical.webp'
import logomobileEN from '../img/Logo_EN_Vertical.webp'
import { default as btn } from '../img/Buton_Home.svg'
import { default as Estinsiguranta } from '../img/Estiinsiguranta.svg'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logoRO from '../img/Logo_RO_Orizontal.webp'
import logoEN from '../img/Logo_EN_Orizontal.webp'
const Home = () => {
  const { t, i18n } = useTranslation()

  const onChange = (event) => {
    i18n.changeLanguage(event.target.value)
    if (event.target.value === 'en') {
      document.getElementById('logo').src = logoEN
      document.getElementById('main-logo-mobile').src = logomobileEN
    } else {
      document.getElementById('logo').src = logoRO
      document.getElementById('main-logo-mobile').src = logomobileRO
    }
  }
  function handleChange(evt) {
    i18n.changeLanguage(evt.target.value)
    if (evt.target.value === 'en') {
      document.getElementById('logo').src = logoEN
    } else {
      document.getElementById('logo').src = logoRO
    }
  }

  return (
    <section>
      <nav className='navbar navbar-home navbar-expand-lg nav-vertical navbar-dark bgc-dark sticky-top'>
        <a className='navbar-brand pe-3' href='/'>
          <img
            src={logoRO}
            className='d-inline-block align-top brand'
            alt='CUTIA ROSIE'
            id='logo'
          ></img>
        </a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon'></span>
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav  mr-auto navbar-center pl-5'>
            {/* <li className="nav-item active pe-3">
          <NavLink to='/services' className="nav-link" activeClassName="main-nav-active">Services</NavLink>
        </li> */}
            <li className='nav-item pe-3'>
              <NavLink
                to='/comingsoon'
                className='nav-link'
                activeClassName='main-nav-active'
              >
                {t('desprenoi')}
              </NavLink>
            </li>
            <li className='nav-item pe-3'>
              <NavLink
                to='/abuz'
                className='nav-link'
                activeClassName='main-nav-active'
              >
                {t('abuzdomestic')}
              </NavLink>
            </li>
            <li className='nav-item pe-3'>
              <NavLink
                to='/'
                className='nav-link single'
                activeClassName='main-nav-active'
              >
                {t('programepreventie')}
              </NavLink>
            </li>
            <li className='nav-item pe-3'>
              <NavLink
                to='/voluntariat'
                className='nav-link single'
                activeClassName='main-nav-active'
              >
                {t('programevoluntariat')}
              </NavLink>
            </li>
            <li className='nav-item pe-3'>
              <NavLink
                to='/arborele-de-familie'
                className='nav-link single'
                activeClassName='main-nav-active'
              >
                {t('arboreledefamilie')}
              </NavLink>
            </li>
            <li className='nav-item pe-'>
              <NavLink
                to='/contact'
                className='nav-link'
                activeClassName='main-nav-active'
              >
                {t('contact')}
              </NavLink>
            </li>
            {/* <li>
              <select id='lang' name='language' onChange={onChange}>
                <option value='ro'>RO</option>
                <option value='en'>EN</option>
              </select>
            </li> */}
          </ul>
        </div>
      </nav>
      <div className='jumbotron jumbotron-fluid mb-0 main bgc-dark'>
        <nav className='navbar nav-homepage-lang navbar-light sticky-top'>
          {/* <li className='sidemenu-homepage'>
            <nav className='slidemenu'>
              <input
                type='radio'
                name='slideItem'
                id='slide-item-1'
                className='slide-toggle'
                checked
                value='ro'
                onChange={handleChange}
              />
              <label for='slide-item-1' value='ro' onChange={handleChange}>
                <span>RO</span>
              </label>

              <input
                type='radio'
                name='slideItem'
                id='slide-item-2'
                className='slide-toggle'
                value='en'
                onChange={handleChange}
              />
              <label for='slide-item-2' value='en' onChange={handleChange}>
                <span>EN</span>
              </label>

              <div className='clear'></div>

              <div className='slider'>
                <div className='bar'></div>
              </div>
            </nav>
          </li> */}
        </nav>
        <div className='container-fluid text-center'>
          <div className='row'>
            <div className='col-sm-12 col-md-12'>
              <img className='main-logo' alt='Cutia roșie' src={logo}></img>
              <img
                className='main-logo-mobile'
                id='main-logo-mobile'
                alt='Cutia roșie'
                src={logomobileRO}
              ></img>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div id='hashtag-main' className='mt-4  text-center mb-5'>
                <img
                  src={Estinsiguranta}
                  alt='#EștiÎnSiguranță'
                  className='hashtag'
                />
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-md-12'>
              <div className='text-center pb-4 mt-5 nodisp'>
                {/* <a href="/menu" className="btn btn-main btn-outline-light btn-lg mt-4">INTRĂ</a> */}
                {/* <div className="mainbtn">
                                    <a href="/menu" className="btn btn-main btn-outline-light btn-lg mt-4">INTRĂ</a>
                                </div> */}
                <div className='container container-button'>
                  <a href='/menu'>
                    <img src={btn} alt='' className='main-btn-svg mt-3' />
                    <div class='centered'>INTRĂ</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home
