import i18n from 'i18next'
import { useTranslation, initReactI18next, Trans } from 'react-i18next'
import translationsEN from '../translations/en.json'
import translationsRO from '../translations/ro.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      ro: { translation: translationsRO },
      en: { translation: translationsEN },
    },
    lng: 'ro',
    fallbackLng: 'ro',
    interpolation: { escapeValue: false },
  })

export default i18n
