import React, { useState } from 'react'
import * as emailjs from 'emailjs-com'

const Connect = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  })

  const { name, email, message } = formData

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value })

  function sendEmail(e) {
    e.preventDefault()

    checkonclick()

    emailjs.send(
      'service_a7tebff',
      'template_vdbnc7x',
      formData,
      'user_NRUirnUGBkFsGHdwc50pv'
    )

    clearFields()
  }

  function checkonclick() {
    if (
      formData.email === '' ||
      formData.name === '' ||
      formData.message === ''
    ) {
      console.log('Please fill')
    } else {
      alert('Your message was succesfully sent!')
    }
  }

  function clearFields() {
    formData.email = ''
    formData.message = ''
    formData.name = ''
  }

  return (
    <div className='container-fluid container-connect bgc-dark pt-5'>
      <div className='row justify-content-center'>
        <div className='col-md-6'>
          <form onSubmit={sendEmail}>
            <h4 className='text-center font-weight-bold bold-red mb-2 mt-3'>
              Contactează-ne pentru mai multe detalii
            </h4>
            <h5 className='text-center mt-3 mail-footer mb-3'>
              <a className='text-white' href='mailto:contact@cutiarosie.ro'>
                contact@cutiarosie.ro
              </a>
            </h5>
            <h1 className='text-center font-weight-bold bold-red mb-5'>
              <a href='https://www.facebook.com/Cutia-Rosie-103602362228922/'>
                <i className='fab fa-facebook'></i>
              </a>
            </h1>
            <div className='form-group'>
              <label id='name'>
                Nume: <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type='text'
                className='form-control'
                placeholder=''
                name='name'
                value={name}
                onChange={onChange}
                required
              />
            </div>
            <div className='form-group'>
              <label for='email'>
                E-mail <span style={{ color: 'red' }}>*</span>
              </label>
              <input
                type='email'
                className='form-control'
                placeholder=' '
                name='email'
                value={email}
                onChange={onChange}
                required
              />
            </div>
            <div className='form-group'>
              <label for='message'>
                Mesaj: <span style={{ color: 'red' }}>*</span>
              </label>
              <textarea
                type='text'
                className='form-control'
                rows='5'
                placeholder=''
                name='message'
                value={message}
                onChange={onChange}
                required
              />
            </div>
            {/* <div class="alert alert-success" role="alert" id='alert-success'>
                        <strong>Thank you!</strong> Your message was succesfully sent!
                    </div>
                    <div class="alert alert-danger" role="alert" id='alert-danger'>
                        <strong>Please</strong> fill in all the fields!
                    </div> */}
            <p className='text-light'>
              {' '}
              <span style={{ color: 'red' }}>*</span> Va rugăm completați toate
              câmpurile sau contactați-ne pe <br></br>
              <a href='https://www.facebook.com/Cutia-Rosie-103602362228922/'>
                FACEBOOK
              </a>
            </p>

            <div className='text-center mt-5'>
              <button
                type='submit'
                className='btn btn-outline-danger btn-lg'
                onClick={checkonclick}
              >
                Trimite
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Connect
