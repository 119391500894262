import React from 'react'
import logomobile from '../img/Logo_RO_Vertical.webp'

const ComingSoon = () => {
  return (
    <section>
      <div className='jumbotron jumbotron-fluid jumbotron-comingsoon text-center text-white mb-0 bgc-dark'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 service'>
              <h1 className='text-center centered2 bold'>
                Pagina va fi disponibilă în curând{' '}
              </h1>
              <img src={logomobile} className='img-fluid mt-5'></img>
            </div>
          </div>
        </div>
        <div id='content'></div>
      </div>
    </section>
  )
}

export default ComingSoon
