import React, { Fragment } from 'react'
import 'bootstrap'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Nav from './components/Nav'
import Home from './components/Home'
import Menu from './components/Menu'
import Arborele from './components/Arborele'
import Voluntariat from './components/Voluntariat'
import Footer from './components/Footer'
import Abuz from './components/Abuz'
import Preventie from './components/Preventie'
import Connect from './components/Connect'
import ComingSoon from './components/ComingSoon'
import './App.css'

function App() {
  return (
    <Router>
      <Fragment>
        <Route exact path='/' component={Home} />
        <Route exact path='/menu' component={Menu} />
        <Route path='/comingsoon'>
          <Nav />
          <ComingSoon />
          <Footer />
        </Route>
        <Route path='/abuz'>
          <Nav />
          <Abuz />
          <Footer />
        </Route>
        <Route path='/arborele-de-familie'>
          <Nav />
          <Arborele />
          <Footer />
        </Route>
        <Route path='/voluntariat'>
          <Nav />
          <Voluntariat />
          <Footer />
        </Route>
        <Route path='/preventie'>
          <Nav />
          <Preventie />
          <Footer />
        </Route>
        <Route path='/contact'>
          <Nav />
          <Connect />
          <Footer />
        </Route>
      </Fragment>
    </Router>
  )
}

export default App
