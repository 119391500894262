import React from 'react'
import logo from '../img/Logo_RO_Orizontal.webp'
import logomobile from '../img/Logo_RO_Vertical.webp'
import i18n from 'i18next'
import { useTranslation } from 'react-i18next'

const Menu = () => {
  const { t } = useTranslation()

  return (
    <div className='jumbotron jumbotron-fluid mb-0 main bgc-dark'>
      <div className='container container-menu mb-0'>
        <div className='container-fluid text-center'>
          <div className='row'>
            <div className='col-md-12 mt-5'>
              <img className='main-logo' alt='Cutia roșie' src={logo}></img>
            </div>
            <nav className='sidebar card py-2 mb-4'>
              <ul className='nav flex-column' id='nav_accordion'>
                <hr width='90%'></hr>
                <li className='nav-item text-uppercase'>
                  <a className='nav-link' href='comingsoon'>
                    {t('desprenoi')}
                  </a>
                </li>
                <hr width='90%'></hr>
                <li className='nav-item text-uppercase'>
                  <a className='nav-link' href='/abuz'>
                    {t('abuzdomestic')}
                  </a>
                </li>
                <hr width='90%'></hr>
                <li className='nav-item text-uppercase'>
                  <a className='nav-link' href='/preventie'>
                    {t('programepreventie')}
                  </a>
                </li>
                <hr width='90%'></hr>
                <li className='nav-item text-uppercase'>
                  <a className='nav-link' href='/voluntariat'>
                    {t('programevoluntariat')}
                  </a>
                </li>
                <hr width='90%'></hr>
                <li className='nav-item text-uppercase'>
                  <a className='nav-link' href='/arborele-de-familie'>
                    {t('arboreledefamilie')}
                  </a>
                </li>
                <hr width='90%'></hr>
                <li className='nav-item text-uppercase'>
                  <a className='nav-link' href='/contact'>
                    {t('contact')}
                  </a>
                </li>
                <hr width='90%'></hr>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Menu
