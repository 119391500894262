import React, { useState } from 'react'
// import logo from '../img/Logo_RO_Orizontal.webp'
import abandon from '../img/Abuz/abandon.webp'
import abuzemotional from '../img/Abuz/abuzemotional.webp'
import abuzfizic from '../img/Abuz/abuzfizic.webp'
import financialabuse from '../img/Abuz/financialabuse.webp'
import selfneglect from '../img/Abuz/selfneglect.webp'
import sexualabuse from '../img/Abuz/sexualabuse.webp'
import { useTranslation } from 'react-i18next'

const Abuz = () => {
  const { t } = useTranslation()
  const [readMore1, setReadMore1] = useState(false)
  const [readMore2, setReadMore2] = useState(false)
  const [readMore3, setReadMore3] = useState(false)
  const [readMore4, setReadMore4] = useState(false)
  const [readMore5, setReadMore5] = useState(false)

  const extraContent1 = (
    <div>
      <li className='extra-content'>{t('abuzulfizic7')} </li>
      <li className='extra-content'>{t('abuzulfizic8')} </li>
    </div>
  )
  const extraContent2 = (
    <div>
      <li className='extra-content'>{t('abuzulemotional7')} </li>
      <li className='extra-content'>{t('abuzulemotional8')}</li>
      <li className='extra-content'>{t('abuzulemotional9')} </li>
      <li className='extra-content'>{t('abuzulemotional10')}</li>
      <li className='extra-content'>{t('abuzulemotional11')} </li>
      <li className='extra-content'>{t('abuzulemotional12')} </li>
      <li className='extra-content'>{t('abuzulemotional13')}</li>
    </div>
  )
  const extraContent3 = (
    <div>
      <li className='extra-content'>{t('abuzfinanciar7')} </li>
    </div>
  )
  const extraContent4 = (
    <div>
      <li className='extra-content'>{t('abuzsexual6')} </li>
      <li className='extra-content'>{t('abuzsexual7')}</li>
      <li className='extra-content'>{t('abuzsexual8')}</li>
    </div>
  )
  const extraContent5 = (
    <div>
      <li className='extra-content'>{t('abandon5')}</li>
      <li className='extra-content'>{t('abandon6')}</li>
    </div>
  )

  const linkName1 = readMore1 ? 'CITEȘTE MAI PUȚIN' : 'CITEȘTE MAI MULT'
  const linkName2 = readMore2 ? 'CITEȘTE MAI PUȚIN' : 'CITEȘTE MAI MULT'
  const linkName3 = readMore3 ? 'CITEȘTE MAI PUȚIN' : 'CITEȘTE MAI MULT'
  const linkName4 = readMore4 ? 'CITEȘTE MAI PUȚIN' : 'CITEȘTE MAI MULT'
  const linkName5 = readMore5 ? 'CITEȘTE MAI PUȚIN' : 'CITEȘTE MAI MULT'

  return (
    <div>
      <div className='jumbotron jumbotron-fluid mb-0 abuz bgc-dark'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center mt-2'>
              <h1 className='abuz-header'>{t('abuzheader')}</h1>
              <h1 className='abuz-header-hashtag mt-3 mb-5'>
                {t('abuzhashtag')}
              </h1>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-8 centered mt-5 text-center'>
              <p>
                {t('abuz1')}
                <br></br>
              </p>
              <p>
                {t('abuz2')}
                <br></br>
              </p>
              <p>
                {t('abuz3')}
                <br></br>
              </p>
              <p>
                {t('abuz4')}
                <br></br>
              </p>
              <p>{t('abuz5')}</p>
              <a href='/contact'>
                {' '}
                <button type='button' className='btn btn-outline-danger'>
                  {t('abuzbutton')}
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className='mb-0'>
        <div className='container cards-container pt-5 mb-5'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h1>{t('formealeabuzului')}</h1>
            </div>
          </div>
        </div>
        <div className='container mx-auto cards mt-5 pb-5 container-cards justify-content-center'>
          <div className='row'>
            <div className='col-md-4'>
              <div className='card'>
                <img src={abuzfizic} className='card-img-top' alt='...' />
                <div className='card-body'>
                  <h5 className='card-title text-center mt-3'>
                    {t('abuztitle')}
                  </h5>
                  <h6 className='card-text mt-3'>{t('abuzulfizic')}</h6>
                  <ul className='mt-2'>
                    <li>{t('abuzulfizic1')}</li>
                    <li>{t('abuzulfizic2')}</li>
                    <li>{t('abuzulfizic3')}</li>
                    <li>{t('abuzulfizic4')}</li>
                    <li>{t('abuzulfizic5')}</li>
                    <li>{t('abuzulfizic6')}</li>
                  </ul>
                  {/* {t('readmore')} */}
                  <a
                    className='mt-5'
                    className='read-more-link'
                    onClick={() => {
                      setReadMore1(!readMore1)
                    }}
                  >
                    <span>
                      <br></br>
                      {linkName1}
                    </span>
                  </a>
                  {readMore1 && extraContent1}
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card'>
                <img
                  src={abuzemotional}
                  className='card-img-top img-fluid'
                  alt='...'
                />
                <div className='card-body'>
                  <h5 className='card-title text-center mt-3'>
                    {t('abuzulemotionaltitlu')}
                  </h5>
                  <h6 className='card-text mt-3'>{t('abuzulemotional1')}</h6>
                  <ul className='mt-2'>
                    <li>{t('abuzulemotional2')}</li>
                    <li>{t('abuzulemotional3')}</li>
                    <li>{t('abuzulemotional4')}</li>
                    <li>{t('abuzulemotional5')}</li>
                    <li>{t('abuzulemotional6')}</li>
                  </ul>
                  {/* <a href='/' className='mt-5'>
                    {t('readmore')}
                  </a> */}
                  <a
                    className='mt-5'
                    className='read-more-link'
                    onClick={() => {
                      setReadMore2(!readMore2)
                    }}
                  >
                    <span>
                      <br></br>
                      {linkName2}
                    </span>
                  </a>
                  {readMore2 && extraContent2}
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card'>
                <img
                  src={financialabuse}
                  className='card-img-top img-fluid'
                  alt='...'
                />
                <div className='card-body'>
                  <h5 className='card-title text-center mt-3'>
                    {t('abuzfinanciartitlu')}
                  </h5>
                  <h6 className='card-text mt-3'>{t('abuzfinanciar1')}</h6>
                  <ul className='mt-2'>
                    <li>{t('abuzfinanciar2')}</li>
                    <li>{t('abuzfinanciar3')}</li>
                    <li>{t('abuzfinanciar4')}</li>
                    <li>{t('abuzfinanciar5')}</li>
                    <li>{t('abuzfinanciar6')}</li>
                  </ul>
                  {/* <a href='/' className='mt-5'>
                    {t('readmore')}
                  </a> */}
                  <a
                    className='mt-5'
                    className='read-more-link'
                    onClick={() => {
                      setReadMore3(!readMore3)
                    }}
                  >
                    <span>
                      <br></br>
                      {linkName3}
                    </span>
                  </a>
                  {readMore3 && extraContent3}
                </div>
              </div>
            </div>
            <div className='mt-5'></div>
            <div className='col-md-4'>
              <div className='card'>
                <img src={sexualabuse} className='card-img-top' alt='...' />
                <div className='card-body'>
                  <h5 className='card-title text-center mt-3'>
                    {t('abuzsexualtitlu')}
                  </h5>
                  <h6 className='card-text mt-3'>{t('abuzsexual1')}</h6>
                  <ul className='mt-2'>
                    <li>{t('abuzsexual2')}</li>
                    <li>{t('abuzsexual3')}</li>
                    <li>{t('abuzsexual4')}</li>
                    <li>{t('abuzsexual5')}</li>
                  </ul>
                  {/* <a href='/' className='mt-5'>
                    {t('readmore')}
                  </a> */}
                  <a
                    className='mt-5'
                    className='read-more-link'
                    onClick={() => {
                      setReadMore4(!readMore4)
                    }}
                  >
                    <span>
                      <br></br>
                      {linkName4}
                    </span>
                  </a>
                  {readMore4 && extraContent4}
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card'>
                <img src={abandon} className='card-img-top' alt='...' />
                <div className='card-body'>
                  <h5 className='card-title text-center mt-3'>
                    {t('abandontitlu')}
                  </h5>
                  <h6 className='card-text mt-3'>{t('abandon1')}</h6>
                  <ul className='mt-2'>
                    <li>{t('abandon2')}</li>
                    <li>{t('abandon3')}</li>
                    <li>{t('abandon4')}</li>
                  </ul>
                  {/* <a href='/' className='mt-5'>
                    {t('readmore')}
                  </a> */}
                  <a
                    className='mt-5'
                    className='read-more-link'
                    onClick={() => {
                      setReadMore5(!readMore5)
                    }}
                  >
                    <span>
                      <br></br>
                      {linkName5}
                    </span>
                  </a>
                  {readMore5 && extraContent5}
                </div>
              </div>
            </div>
            <div className='col-sm-4'>
              <div className='card'>
                <img src={selfneglect} className='card-img-top' alt='...' />
                <div className='card-body'>
                  <h5 className='card-title text-center mt-3'>
                    {t('neglijaretitlu')}
                  </h5>
                  <h6 className='card-text mt-3'>{t('neglijare1')}</h6>
                  <ul className='mt-2'>
                    <li>{t('neglijare2')} </li>
                    <li>{t('neglijare3')}</li>
                    <li>{t('neglijare4')}</li>
                    <li>{t('neglijare5')}</li>
                    <li>{t('neglijare6')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Abuz
